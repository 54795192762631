import { Typography } from '@mui/material';
import { getKeyByLabel } from '@/helpers/exchangeNames';
import mapChainData from '@/helpers/mapChainData';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import AnomalyTimeLineChart from '@/components/molecules/Charts/AnomalyTimeLineChart';
import {
  StyledDetails,
  StyledLogoRow,
  StyledRow,
  StyledWrapper,
  StyledLink,
} from '@/views/MonitoringView/components/MonititoredEntityItem/MonitoredEntityItem.styled';
import { ERoutes } from '@/types/routes';
import { TSeverity } from '@/types/alert';
import { IBalance } from '@/types/balance';

interface IMonitoredEntityItemProps {
  agioId: string;
  entity: string | null;
  severity?: TSeverity | null;
  balances: IBalance[] | null;
}

export const MonitoredEntityItem = ({ agioId, entity, severity, balances }: IMonitoredEntityItemProps) => {
  const formattedData = balances ? mapChainData(balances) : [];
  const href = entity ? `${ERoutes.MONITORING}/${getKeyByLabel(entity)}` : '';

  return (
    <StyledLink href={href} underline="none">
      <StyledWrapper>
        <StyledDetails>
          <StyledLogoRow>
            {entity && (
              <>
                <ExchangeLogo companyName={getKeyByLabel(entity)} />
                <Typography color="white.100" fontSize="24px">
                  {entity}
                </Typography>
              </>
            )}
          </StyledLogoRow>

          <StyledRow>
            <Typography color="#FFFFFF80" fontSize="14px">
              Severity
            </Typography>
            {severity && <AlertSeverityBarsIcon severity={severity} />}
          </StyledRow>

          <StyledRow>
            <Typography color="#FFFFFF80" fontSize="14px">
              Latest Anomalies
            </Typography>
            <Typography color="white.100" fontSize="16px">
              3
            </Typography>
          </StyledRow>
        </StyledDetails>

        <AnomalyTimeLineChart alertId={agioId} chartData={formattedData} />
      </StyledWrapper>
    </StyledLink>
  );
};
