import React from 'react';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Controller, UseFormReturn } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import {
  StyledWrapper,
  StyledAutocomplete,
  StyledPaper,
} from '@/views/MonitoringSettingsView/components/EntitiesMonitoring/EntitiesMonitoring.styled';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IEntitiesMonitoringProps {
  formMethods: UseFormReturn<IMonitoringForm>;
}

export const EntitiesMonitoring = ({ formMethods }: IEntitiesMonitoringProps) => {
  const { exchanges } = useEntitiesList();

  return (
    <StyledWrapper>
      <Typography variant="h2" color="white.100" fontWeight="semibold">
        Entities to monitor
      </Typography>

      <Controller
        render={({ field }) => {
          return (
            <StyledAutocomplete
              multiple
              id="entities-list-to-monitor"
              disableCloseOnSelect
              disablePortal
              options={exchanges.map((e) => e.label)}
              renderOption={(props, option, { selected }) => {
                if (typeof option === 'string') {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  );
                }
              }}
              renderInput={(params) => <TextField {...params} label="Entities" />}
              PaperComponent={({ children }) => <StyledPaper>{children}</StyledPaper>}
              value={field.value}
              onChange={(event, newValues) => {
                field.onChange(newValues);
              }}
              onBlur={field.onBlur}
            />
          );
        }}
        name="entities"
        control={formMethods.control}
      />
    </StyledWrapper>
  );
};
