import { Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { formatFloatToPercent, formatToUSD } from '@/helpers/helpers';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import {
  StyledColumnWrapper,
  StyledEntityWrapper,
  StyledRowDataWrapper,
  StyledWrapper,
  StyledLink,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { ERoutes } from '@/types/routes';
import { EntityMonitoredData } from '@/views/EntityMonitoringView/EntityMonitoring';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';

export const EntityDetails = ({
  entity,
  balance,
  inflows,
  outflows,
  totalflows,
  pd,
  pdChange,
  actualSeverity,
}: Omit<EntityMonitoredData, 'anomalies'>) => {
  const { shouldDisplayRisks } = usePermissionsManager();

  const blurrStyle = { filter: `${shouldDisplayRisks ? 'none' : 'blur(5px)'}` };

  return (
    <StyledWrapper>
      <StyledColumnWrapper>
        <StyledEntityWrapper>
          <ExchangeLogo companyName={entity} />
          <Typography color="white.100" fontSize="24px">
            {getFullExchangeName(entity)}
          </Typography>
        </StyledEntityWrapper>
        <StyledRowDataWrapper>
          <Typography color="#ffffff80" fontSize="14px">
            Balance
          </Typography>
          <Typography color="white.100" fontSize="16px">
            {formatToUSD(balance)}
          </Typography>
        </StyledRowDataWrapper>
        <StyledRowDataWrapper>
          <Typography color="#ffffff80" fontSize="14px">
            24hr Outflows
          </Typography>
          <Typography color="white.100" fontSize="16px">
            {formatToUSD(outflows)}
          </Typography>
        </StyledRowDataWrapper>
        <StyledRowDataWrapper>
          <Typography color="#ffffff80" fontSize="14px">
            24hr Inflows
          </Typography>
          <Typography color="white.100" fontSize="16px">
            {formatToUSD(inflows)}
          </Typography>
        </StyledRowDataWrapper>
        <StyledRowDataWrapper>
          <Typography color="#ffffff80" fontSize="14px">
            24hr Total flows
          </Typography>
          <Typography color="white.100" fontSize="16px">
            {formatToUSD(totalflows)}
          </Typography>
        </StyledRowDataWrapper>
      </StyledColumnWrapper>

      <StyledColumnWrapper>
        <Typography color="white.100" fontSize="14px">
          Default risks
        </Typography>
        <StyledRowDataWrapper>
          <Typography color="#ffffff80" fontSize="14px">
            1 year PD
          </Typography>
          <Typography color="white.100" fontSize="16px" style={blurrStyle}>
            {shouldDisplayRisks ? formatFloatToPercent(pd) : 'XX.XX%'}
          </Typography>
        </StyledRowDataWrapper>
        <StyledRowDataWrapper>
          <Typography color="#ffffff80" fontSize="14px">
            PD change
          </Typography>
          <Typography color="white.100" fontSize="16px" style={blurrStyle}>
            {shouldDisplayRisks ? formatFloatToPercent(pdChange) : 'XX.XX%'}
          </Typography>
        </StyledRowDataWrapper>
        <StyledRowDataWrapper>
          <StyledLink href={`${ERoutes.RATINGS}/${entity}`} underline="none">
            <Typography color="#ffffff80" fontSize="14px" display="flex" alignItems="center" gap="3px">
              Go to details <ArrowForwardIcon fontSize="small" />
            </Typography>
          </StyledLink>
        </StyledRowDataWrapper>
      </StyledColumnWrapper>

      <StyledColumnWrapper>
        <Typography color="white.100" fontSize="14px">
          Short term risk
        </Typography>
        <StyledRowDataWrapper>
          <Typography color="#ffffff80" fontSize="14px">
            Actual severity
          </Typography>
          <AlertSeverityBarsIcon severity={actualSeverity} />
        </StyledRowDataWrapper>
      </StyledColumnWrapper>
    </StyledWrapper>
  );
};
