import { useGetUserEntities } from '@/api/userEntities/userEntities.hooks';
import { EmptyMonitoringView } from '@/views/MonitoringView/components/EmptyMonitoringView/EmptyMonitoringView';
import { ListOfEntities } from '@/views/MonitoringView/components/ListOfEntities/ListOfEntities';
import Loader from '@/components/atoms/Loader/Loader';
import { StyledContainer, StyledLoaderContainer } from '@/views/MonitoringView/MonitoringView.styled';

export const MonitoringView = () => {
  const { fetchedUserEntities, isLoading, error } = useGetUserEntities();

  if (isLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (error || !fetchedUserEntities) {
    throw error;
  }

  if (fetchedUserEntities && !fetchedUserEntities.length) {
    return <EmptyMonitoringView />;
  }

  return (
    <StyledContainer>
      <ListOfEntities monitoredEntities={fetchedUserEntities} />
    </StyledContainer>
  );
};
