import styled from '@emotion/styled';
import { Link } from '@mui/material';

export const StyledLink = styled(Link)`
  &:hover > div {
    box-shadow: 0 0 3px 3px #2e2e31;
    transform: scale(1.01);
  }
`;

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 30%) auto;
  gap: 25px;
  padding: 25px;
  border: 1px solid #2e2e31;
  width: 100%;
  overflow-x: scroll;
  transition: box-shadow 0.3s, transform 0.3s;
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 55px;
  padding: 15px 25px;
`;

export const StyledLogoRow = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
