import { useContext, useMemo } from 'react';
import { assertIsDefined } from '@/helpers/assertions';
import { NewFeatureContext } from '@/providers/NewFeatureContextProvider/NewFeatureContextProvider';

const uat = import.meta.env.VITE_UAT_URL;
const localhost = import.meta.env.VITE_LOCALHOST_URL;

export const useNewFeatureManager = () => {
  const origin = window.location.origin;

  const isPermitted = useMemo(() => {
    return origin === uat || origin === localhost;
  }, [origin]);

  return {
    isNewFeaturePermitted: isPermitted,
  };
};

export const useNewFeature = () => {
  const { isNewFeaturePermitted } = useContext(NewFeatureContext);

  assertIsDefined(isNewFeaturePermitted, '"INewFeatureContext.isNewFeaturePermitted has to be defined!"');

  return {
    isNewFeaturePermitted,
  };
};
