import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 22px 24px;
  width: 100%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 25px;
  border: 1px solid #2e2e31;
  width: 100%;
`;
