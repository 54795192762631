import styled from '@emotion/styled';

const MENU_WIDTH = '80px';
const VIEW_PADDINGS = '120px';
const SECTION_PADDINGS = '60px';

export const StyledGridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, minmax(150px, 1fr)) 40px;
  grid-template-rows: repeat(1, 1fr);
  padding-block: 10px;
  border-top: 1px solid #f5f5f5;
  color: #ffffff;
  overflow-y: auto;

  > div:nth-of-type(6n + 1)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -10px;
    width: calc(100vw - ${MENU_WIDTH} - ${VIEW_PADDINGS} - ${SECTION_PADDINGS} - 3px);
    height: 1px;
    background: #f5f5f5;
  }
`;

export const StyledGridHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  color: #f5f5f5;
  position: relative;
`;
