import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from '@mui/material';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 15px 25px;
  border: 1px solid #2e2e31;
`;

export const StyledDataGrid = styled(DataGrid)`
  border-color: transparent;
  flex: none;
  height: auto;

  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
    border: none;
  }

  .MuiDataGrid-columnHeaderTitle {
    opacity: 0.5;
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-footerContainer {
    border-bottom: 1px solid #2e2e31;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-row.Mui-selected,
  .MuiDataGrid-row.Mui-selected.Mui-hovered,
  .MuiDataGrid-row:hover {
    background: #222;
    cursor: pointer;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
`;

export const StyledLink = styled(Link)`
  p {
    transition: color 0.3s;
  }

  &:hover p {
    color: #5967e3;
  }
`;
