import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { defaultMonitoringFormValues } from '@/views/MonitoringSettingsView/utils/constants';
import { useCreateMonitoringSettings, useGetMonitoringSettings } from '@/api/user/user.hooks';
import { getFullExchangeName, getKeyByLabel } from '@/helpers/exchangeNames';
import { ERoutes } from '@/types/routes';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';

export const useMonitoringForm = () => {
  const navigate = useNavigate();

  const monitoringMethods = useForm<IMonitoringForm>({
    defaultValues: defaultMonitoringFormValues,
  });

  const { createMonitoringSettings, isLoading } = useCreateMonitoringSettings();

  const { handleSubmit, setValue } = monitoringMethods;

  const onMonitoringSubmit = useCallback(
    async (data: IMonitoringForm) => {
      const payload = {
        entities: data.entities.map((e) => getKeyByLabel(e)),
      };

      createMonitoringSettings(payload, {
        onSuccess: () => {
          navigate(ERoutes.MONITORING);
        },
        onError: (error) => {
          console.error('Monitoring settings setup failed: ', error.message);
        },
      });
    },
    [createMonitoringSettings, navigate],
  );

  const handleMonitoringFormSubmit = handleSubmit(onMonitoringSubmit);

  const { fetchedSettings, isSuccess } = useGetMonitoringSettings();

  useEffect(() => {
    if (!fetchedSettings || !isSuccess) return;

    if (fetchedSettings.entities.length > 0) {
      setValue(
        'entities',
        fetchedSettings.entities.map((e) => getFullExchangeName(e)),
      );
    }
  }, [fetchedSettings, isSuccess, setValue]);

  return {
    monitoringMethods,
    handleMonitoringFormSubmit,
    isSettingsLoading: isLoading,
  };
};
