import React from 'react';
import { EntityMonitoringView } from '@/views/EntityMonitoringView/EntityMonitoringView';
import { TSeverity } from '@/types/alert';
import { TAnomaly } from '@/types/anomaly';

export interface EntityMonitoredData {
  entity: string;
  balance: number;
  outflows: number;
  inflows: number;
  totalflows: number;
  pd: number;
  pdChange: number;
  actualSeverity: TSeverity;
  anomalies: TAnomaly[];
  // latestAnomalies: number;
  // chainData: IChainData[] | null;
}

const mocked_data: EntityMonitoredData = {
  entity: 'binance',
  balance: 5125023989.6881075,
  outflows: 67596420.45522349,
  inflows: 67628134.98476292,
  totalflows: 135224555.4399864,
  pd: 17.21,
  pdChange: 5.530000000000001,
  actualSeverity: 'high',
  anomalies: [
    {
      id: '66ec3e2744327c6bc842c9bd',
      timestamp: '2024-09-19T14:09:00.567000',
      zScore: 6.646888239824925,
      changePercentage: -8.38,
      changeAmount: -431256377.04834545,
      insight:
        '<p>An anomaly was detected on the total flows for HTX at 2024-09-19 14:09:00. The detected anomaly shows a significant total flow of $1,598,606,759.72, with a z-score of 6.64. The change in the balance is recorded at -$431,256,377.05, representing an 8.38% decrease.</p><p><br></p><p>In the past 3 hours, $437M of BTC was transferred in two separate transactions to a Poloniex deposit address, 1NBX1UZE3EFPTnYNkDfVhRADvVc8v6pRYu. The address has not been active since March 2020.</p><p><br></p><p>Transaction Hashes: <a href="https://mempool.space/tx/b47c3ce6f29987888cbd499a26899ea419ad154a654581707da75eb59eb51733" rel="noopener noreferrer" target="_blank">TRX Hash 1</a> <a href="https://mempool.space/tx/b138cb91c8fe18c79a299fc7b960e47f26c91ea8f3493045d273bf8ae61c1887" rel="noopener noreferrer" target="_blank">TRX HASH 2</a></p>',
      metadata: {
        entityType: 'cex',
        interval: 'interval',
        exchange: 'huobi',
        method: 'bp',
        symbol: '_total',
        source: 'arkham',
        type: 'totalflow',
        units: 'USD',
      },
      entity: {
        displayName: 'huobi',
        isRated: true,
      },
      reviewed: true,
      deleted: false,
      severity: 'none',
      updates: [],
      latestHourlyChaindata: null,
      // chainData: [
      //   {
      //     id: '66ebb22b0b61fc0dc2b46add',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-18T00:00:00',
      //     intervalStart: null,
      //     value: 5125023989.6881075,
      //     deleted: false,
      //   },
      //   {
      //     id: '66ea62aa0b61fc0dc29b2efd',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-17T00:00:00',
      //     intervalStart: null,
      //     value: 4946864494.991487,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab95',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-16T00:00:00',
      //     intervalStart: null,
      //     value: 4998424853.539955,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e7bdb4cd0346449cc327ef',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-15T00:00:00',
      //     intervalStart: null,
      //     value: 5087449394.27221,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e66c298eaffcf799c1e976',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-14T00:00:00',
      //     intervalStart: null,
      //     value: 5135618218.674248,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e5586b8eaffcf799a71e75',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-13T00:00:00',
      //     intervalStart: null,
      //     value: 5049704120.069827,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e3c9108eaffcf7997c47a5',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-12T00:00:00',
      //     intervalStart: null,
      //     value: 5012716975.492248,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e277f78eaffcf79955dab2',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-11T00:00:00',
      //     intervalStart: null,
      //     value: 5056670410.9904785,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e12b348eaffcf7992c3ff4',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-10T00:00:00',
      //     intervalStart: null,
      //     value: 4861460588.10858,
      //     deleted: false,
      //   },
      //   {
      //     id: '66dfd4ee8eaffcf79901665b',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-09T00:00:00',
      //     intervalStart: null,
      //     value: 4913881724.726429,
      //     deleted: false,
      //   },
      //   {
      //     id: '66debdb28eaffcf799dafea8',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-08T00:00:00',
      //     intervalStart: null,
      //     value: 4841262058.837276,
      //     deleted: false,
      //   },
      //   {
      //     id: '66dd3b008465ef067cd4d8cd',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-07T00:00:00',
      //     intervalStart: null,
      //     value: 4787505343.482284,
      //     deleted: false,
      //   },
      //   {
      //     id: '66dbe4bc8465ef067cab3fb0',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-06T00:00:00',
      //     intervalStart: null,
      //     value: 4848763408.121721,
      //     deleted: false,
      //   },
      //   {
      //     id: '66da8e938465ef067c7fe19b',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-05T00:00:00',
      //     intervalStart: null,
      //     value: 4826026183.512631,
      //     deleted: false,
      //   },
      //   {
      //     id: '66d964b38465ef067c5673a7',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-04T00:00:00',
      //     intervalStart: null,
      //     value: 4766573655.015624,
      //     deleted: false,
      //   },
      //   {
      //     id: '66d7eafd8465ef067cf83e7f',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-03T00:00:00',
      //     intervalStart: null,
      //     value: 5156021893.965544,
      //     deleted: false,
      //   },
      //   {
      //     id: '66d699e18465ef067cd1cab8',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-02T00:00:00',
      //     intervalStart: null,
      //     value: 5092158572.692905,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab7d',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-01T00:00:00',
      //     intervalStart: null,
      //     value: 5283234748.213739,
      //     deleted: false,
      //   },
      //   {
      //     id: '66d3f9243e2b43b7aba2b505',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-31T00:00:00',
      //     intervalStart: null,
      //     value: 5435004404.768504,
      //     deleted: false,
      //   },
      //   {
      //     id: '66d2a5163e2b43b7ab6110ce',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-30T00:00:00',
      //     intervalStart: null,
      //     value: 5229361730.148989,
      //     deleted: false,
      //   },
      //   {
      //     id: '66d153ca3e2b43b7ab268984',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-29T00:00:00',
      //     intervalStart: null,
      //     value: 5380290628.423202,
      //     deleted: false,
      //   },
      //   {
      //     id: '66d077c93e2b43b7ab6cf3c8',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-28T00:00:00',
      //     intervalStart: null,
      //     value: 5313170103.375628,
      //     deleted: false,
      //   },
      //   {
      //     id: '66ceb1093e2b43b7abb033da',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-27T00:00:00',
      //     intervalStart: null,
      //     value: 5452924792.132412,
      //     deleted: false,
      //   },
      //   {
      //     id: '66cd8c4d3e2b43b7aba3625c',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-26T00:00:00',
      //     intervalStart: null,
      //     value: 5919594710.876257,
      //     deleted: false,
      //   },
      //   {
      //     id: '66cc0ec3e3f4f9e09c508007',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-25T00:00:00',
      //     intervalStart: null,
      //     value: 5825995933.652191,
      //     deleted: false,
      //   },
      //   {
      //     id: '66cae788e3f4f9e09c4c7ccd',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-24T00:00:00',
      //     intervalStart: null,
      //     value: 5805368525.756886,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c96c07798656e06a7c2db2',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-23T00:00:00',
      //     intervalStart: null,
      //     value: 5567987526.3080225,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c81921ae957b133a90cf1e',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-22T00:00:00',
      //     intervalStart: null,
      //     value: 5303479042.159455,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c6c7a5ae957b133a7d921f',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-21T00:00:00',
      //     intervalStart: null,
      //     value: 5311458940.264846,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c57657ae957b133a676a1a',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-20T00:00:00',
      //     intervalStart: null,
      //     value: 5086916416.00137,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c42ffcae957b133a6ada6b',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-19T00:00:00',
      //     intervalStart: null,
      //     value: 4883826318.838874,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c30597ae957b133a926072',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-18T00:00:00',
      //     intervalStart: null,
      //     value: 4998442461.508968,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c1ebf5cc4b5db6719a21a9',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-17T00:00:00',
      //     intervalStart: null,
      //     value: 4878935354.098651,
      //     deleted: false,
      //   },
      //   {
      //     id: '66c0a9e2fc84245b9b2621bf',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-16T00:00:00',
      //     intervalStart: null,
      //     value: 4998602657.285884,
      //     deleted: false,
      //   },
      //   {
      //     id: '66bed17c642486edf69a05a1',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-15T00:00:00',
      //     intervalStart: null,
      //     value: 5003436684.572465,
      //     deleted: false,
      //   },
      //   {
      //     id: '66bd7f7b38e6cc6d6d83d338',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-14T00:00:00',
      //     intervalStart: null,
      //     value: 5065752669.232759,
      //     deleted: false,
      //   },
      //   {
      //     id: '66bc2dd6dc3a815e6c396f24',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-13T00:00:00',
      //     intervalStart: null,
      //     value: 4809543193.085249,
      //     deleted: false,
      //   },
      //   {
      //     id: '66badc7d596e443fdb1d8081',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-12T00:00:00',
      //     intervalStart: null,
      //     value: 4756546694.045289,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b9a5c8e7d95c12b5656394',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-11T00:00:00',
      //     intervalStart: null,
      //     value: 4924127470.442571,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b8390e45a719e5d0271ffd',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-10T00:00:00',
      //     intervalStart: null,
      //     value: 4870340310.311846,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b6e7a645a719e5d048c86b',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-09T00:00:00',
      //     intervalStart: null,
      //     value: 4878166471.832045,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b59d6f45a719e5d076e10c',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-08T00:00:00',
      //     intervalStart: null,
      //     value: 4725583373.266041,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b44a2254336841e116c8ac',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-07T00:00:00',
      //     intervalStart: null,
      //     value: 4776639731.917667,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b3f1cd54336841e1e73700',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-06T00:00:00',
      //     intervalStart: null,
      //     value: 4542158773.359035,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b1a1ee3a96f39c45461270',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-05T00:00:00',
      //     intervalStart: null,
      //     value: 4755596892.672535,
      //     deleted: false,
      //   },
      //   {
      //     id: '66b09e663a96f39c458d5f9a',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-04T00:00:00',
      //     intervalStart: null,
      //     value: 4889837041.146357,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab4e',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-03T00:00:00',
      //     intervalStart: null,
      //     value: 4925211646.3555155,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab4d',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-02T00:00:00',
      //     intervalStart: null,
      //     value: 5345875347.049561,
      //     deleted: false,
      //   },
      //   {
      //     id: '66ac5db8910731002fddef14',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-08-01T00:00:00',
      //     intervalStart: null,
      //     value: 5332349879.468037,
      //     deleted: false,
      //   },
      //   {
      //     id: '66ab09956ef350e942dc938a',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-31T00:00:00',
      //     intervalStart: null,
      //     value: 5466200259.858747,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a9b7fbf8de99395b580e33',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-30T00:00:00',
      //     intervalStart: null,
      //     value: 5405813131.1784,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a866f8f8de99395b19489a',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-29T00:00:00',
      //     intervalStart: null,
      //     value: 5446024100.081546,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a71558b39d6264c6ba4756',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-28T00:00:00',
      //     intervalStart: null,
      //     value: 5600072857.826845,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a5c6d6b39d6264c697b6b8',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-27T00:00:00',
      //     intervalStart: null,
      //     value: 5598885584.889476,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a47571b39d6264c6756019',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-26T00:00:00',
      //     intervalStart: null,
      //     value: 5316091896.635662,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a320a9b39d6264c65252a8',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-25T00:00:00',
      //     intervalStart: null,
      //     value: 5318353606.239499,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a1cf38b39d6264c62f87a0',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-24T00:00:00',
      //     intervalStart: null,
      //     value: 5339551304.066605,
      //     deleted: false,
      //   },
      //   {
      //     id: '66a07db8b39d6264c60c45bf',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-23T00:00:00',
      //     intervalStart: null,
      //     value: 5389995881.28294,
      //     deleted: false,
      //   },
      //   {
      //     id: '669f2c2ab39d6264c6e91431',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-22T00:00:00',
      //     intervalStart: null,
      //     value: 5449782880.911824,
      //     deleted: false,
      //   },
      //   {
      //     id: '669de1c6b39d6264c6c6fbe5',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-21T00:00:00',
      //     intervalStart: null,
      //     value: 5402154690.932175,
      //     deleted: false,
      //   },
      //   {
      //     id: '669c8980b39d6264c6a464f3',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-20T00:00:00',
      //     intervalStart: null,
      //     value: 5387383667.835496,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab38',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-19T00:00:00',
      //     intervalStart: null,
      //     value: 5248886608.0226755,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab34',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-18T00:00:00',
      //     intervalStart: null,
      //     value: 5447211016.907141,
      //     deleted: false,
      //   },
      //   {
      //     id: '669894d248d1b7f9960d28af',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-17T00:00:00',
      //     intervalStart: null,
      //     value: 5285277548.680936,
      //     deleted: false,
      //   },
      //   {
      //     id: '669742b748d1b7f996eb0aa1',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-16T00:00:00',
      //     intervalStart: null,
      //     value: 5339042187.715985,
      //     deleted: false,
      //   },
      //   {
      //     id: '6695f12048d1b7f996cb778a',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-15T00:00:00',
      //     intervalStart: null,
      //     value: 5137754237.767625,
      //     deleted: false,
      //   },
      //   {
      //     id: '6695255f48d1b7f996b8a83e',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-14T00:00:00',
      //     intervalStart: null,
      //     value: 5262065778.944168,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab30',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-13T00:00:00',
      //     intervalStart: null,
      //     value: 5152626664.457223,
      //     deleted: false,
      //   },
      //   {
      //     id: '6691fc9248d1b7f996700154',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-12T00:00:00',
      //     intervalStart: null,
      //     value: 4931246944.611362,
      //     deleted: false,
      //   },
      //   {
      //     id: '6690ab1448d1b7f996510aa3',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-11T00:00:00',
      //     intervalStart: null,
      //     value: 4904227765.669695,
      //     deleted: false,
      //   },
      //   {
      //     id: '668f59c448d1b7f9963224c7',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-10T00:00:00',
      //     intervalStart: null,
      //     value: 4896342039.3638,
      //     deleted: false,
      //   },
      //   {
      //     id: '668e081048d1b7f99612e6c4',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-09T00:00:00',
      //     intervalStart: null,
      //     value: 4787787018.076751,
      //     deleted: false,
      //   },
      //   {
      //     id: '668cb6a578b5f3476c424b44',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-08T00:00:00',
      //     intervalStart: null,
      //     value: 4876241375.984521,
      //     deleted: false,
      //   },
      //   {
      //     id: '668c361c78b5f3476c35617b',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-07T00:00:00',
      //     intervalStart: null,
      //     value: 5079286668.637197,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab28',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-06T00:00:00',
      //     intervalStart: null,
      //     value: 4924578202.617987,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab27',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-05T00:00:00',
      //     intervalStart: null,
      //     value: 4980745780.747717,
      //     deleted: false,
      //   },
      //   {
      //     id: '66877e8d78b5f3476cc1d139',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-04T00:00:00',
      //     intervalStart: null,
      //     value: 5194216277.451487,
      //     deleted: false,
      //   },
      //   {
      //     id: '668628ff78b5f3476ca20fb1',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-03T00:00:00',
      //     intervalStart: null,
      //     value: 5286446384.917385,
      //     deleted: false,
      //   },
      //   {
      //     id: '6684cda978b5f3476c80ec08',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-02T00:00:00',
      //     intervalStart: null,
      //     value: 5322263399.17903,
      //     deleted: false,
      //   },
      //   {
      //     id: '66837c2a78b5f3476c5f9a2d',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-07-01T00:00:00',
      //     intervalStart: null,
      //     value: 5215192694.704031,
      //     deleted: false,
      //   },
      //   {
      //     id: '66822e0d78b5f3476c3ed483',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-30T00:00:00',
      //     intervalStart: null,
      //     value: 4970047317.671825,
      //     deleted: false,
      //   },
      //   {
      //     id: '66e934930b61fc0dc28aab1b',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-29T00:00:00',
      //     intervalStart: null,
      //     value: 4948812700.930718,
      //     deleted: false,
      //   },
      //   {
      //     id: '667f879f78b5f3476cfc5f7c',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-28T00:00:00',
      //     intervalStart: null,
      //     value: 5180862298.247712,
      //     deleted: false,
      //   },
      //   {
      //     id: '667e361e78b5f3476cdaeb74',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-27T00:00:00',
      //     intervalStart: null,
      //     value: 5170925476.898636,
      //     deleted: false,
      //   },
      //   {
      //     id: '667ce496108ae2577bd17044',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-26T00:00:00',
      //     intervalStart: null,
      //     value: 5219443290.004815,
      //     deleted: false,
      //   },
      //   {
      //     id: '667b931d108ae2577bb0c135',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-25T00:00:00',
      //     intervalStart: null,
      //     value: 5099197232.4698515,
      //     deleted: false,
      //   },
      //   {
      //     id: '667a4195108ae2577b9024cb',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-24T00:00:00',
      //     intervalStart: null,
      //     value: 4950484337.4492855,
      //     deleted: false,
      //   },
      //   {
      //     id: '6678f4ef108ae2577b6f8740',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-23T00:00:00',
      //     intervalStart: null,
      //     value: 4816060089.289782,
      //     deleted: false,
      //   },
      //   {
      //     id: '66779eae108ae2577b4ed5cb',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-06-22T00:00:00',
      //     intervalStart: null,
      //     value: 4985336069.641851,
      //     deleted: false,
      //   },
      //   {
      //     id: '66ec3fb40000000000279f20',
      //     metadata: {
      //       symbol: '_total',
      //       type: 'balance',
      //       source: 'arkham',
      //       units: 'USD',
      //       exchange: 'huobi',
      //       isAggregate: false,
      //     },
      //     timestamp: '2024-09-19T00:00:00',
      //     intervalStart: null,
      //     value: 4714217059.85,
      //     deleted: false,
      //   },
      // ],
      // inflowData: [
      //   {
      //     timestamp: '2024-06-22T00:00:00',
      //     value: 67628134.98476292,
      //   },
      //   {
      //     timestamp: '2024-06-23T00:00:00',
      //     value: 53824960.06702605,
      //   },
      //   {
      //     timestamp: '2024-06-24T00:00:00',
      //     value: 396295475.8296113,
      //   },
      //   {
      //     timestamp: '2024-06-25T00:00:00',
      //     value: 148727422.6884956,
      //   },
      //   {
      //     timestamp: '2024-06-26T00:00:00',
      //     value: 116493219.05802783,
      //   },
      //   {
      //     timestamp: '2024-06-27T00:00:00',
      //     value: 110672991.4579725,
      //   },
      //   {
      //     timestamp: '2024-06-28T00:00:00',
      //     value: 111463876.94510914,
      //   },
      //   {
      //     timestamp: '2024-06-29T00:00:00',
      //     value: 57686456.71112794,
      //   },
      //   {
      //     timestamp: '2024-06-30T00:00:00',
      //     value: 55506978.70163668,
      //   },
      //   {
      //     timestamp: '2024-07-01T00:00:00',
      //     value: 140860937.7300296,
      //   },
      //   {
      //     timestamp: '2024-07-02T00:00:00',
      //     value: 124752055.06875767,
      //   },
      //   {
      //     timestamp: '2024-07-03T00:00:00',
      //     value: 151196775.7966572,
      //   },
      //   {
      //     timestamp: '2024-07-04T00:00:00',
      //     value: 133146257.24380988,
      //   },
      //   {
      //     timestamp: '2024-07-05T00:00:00',
      //     value: 168779537.87357163,
      //   },
      //   {
      //     timestamp: '2024-07-06T00:00:00',
      //     value: 146890358.1584295,
      //   },
      //   {
      //     timestamp: '2024-07-07T00:00:00',
      //     value: 50279323.33276592,
      //   },
      //   {
      //     timestamp: '2024-07-08T00:00:00',
      //     value: 109342699.17373171,
      //   },
      //   {
      //     timestamp: '2024-07-09T00:00:00',
      //     value: 97917850.77055566,
      //   },
      //   {
      //     timestamp: '2024-07-10T00:00:00',
      //     value: 93028648.18483686,
      //   },
      //   {
      //     timestamp: '2024-07-11T00:00:00',
      //     value: 86973793.3656006,
      //   },
      //   {
      //     timestamp: '2024-07-12T00:00:00',
      //     value: 86354989.59249333,
      //   },
      //   {
      //     timestamp: '2024-07-13T00:00:00',
      //     value: 60146866.05123218,
      //   },
      //   {
      //     timestamp: '2024-07-14T00:00:00',
      //     value: 55669290.798506305,
      //   },
      //   {
      //     timestamp: '2024-07-15T00:00:00',
      //     value: 173403930.11887464,
      //   },
      //   {
      //     timestamp: '2024-07-16T00:00:00',
      //     value: 178878520.83491978,
      //   },
      //   {
      //     timestamp: '2024-07-17T00:00:00',
      //     value: 122784171.84912315,
      //   },
      //   {
      //     timestamp: '2024-07-18T00:00:00',
      //     value: 140700139.85029554,
      //   },
      //   {
      //     timestamp: '2024-07-19T00:00:00',
      //     value: 146301710.6129174,
      //   },
      //   {
      //     timestamp: '2024-07-20T00:00:00',
      //     value: 61614736.74775292,
      //   },
      //   {
      //     timestamp: '2024-07-21T00:00:00',
      //     value: 75798583.70097992,
      //   },
      //   {
      //     timestamp: '2024-07-22T00:00:00',
      //     value: 151330201.6935735,
      //   },
      //   {
      //     timestamp: '2024-07-23T00:00:00',
      //     value: 122593802.63919108,
      //   },
      //   {
      //     timestamp: '2024-07-24T00:00:00',
      //     value: 118186596.68306611,
      //   },
      //   {
      //     timestamp: '2024-07-25T00:00:00',
      //     value: 113123101.36574359,
      //   },
      //   {
      //     timestamp: '2024-07-26T00:00:00',
      //     value: 159995242.26306075,
      //   },
      //   {
      //     timestamp: '2024-07-27T00:00:00',
      //     value: 100015153.13170469,
      //   },
      //   {
      //     timestamp: '2024-07-28T00:00:00',
      //     value: 62111995.00600158,
      //   },
      //   {
      //     timestamp: '2024-07-29T00:00:00',
      //     value: 154001312.44403523,
      //   },
      //   {
      //     timestamp: '2024-07-30T00:00:00',
      //     value: 139252283.34407416,
      //   },
      //   {
      //     timestamp: '2024-07-31T00:00:00',
      //     value: 147717444.59150386,
      //   },
      //   {
      //     timestamp: '2024-08-01T00:00:00',
      //     value: 148240438.30132446,
      //   },
      //   {
      //     timestamp: '2024-08-02T00:00:00',
      //     value: 121811576.28160079,
      //   },
      //   {
      //     timestamp: '2024-08-03T00:00:00',
      //     value: 259845587.4356585,
      //   },
      //   {
      //     timestamp: '2024-08-04T00:00:00',
      //     value: 94672623.97987589,
      //   },
      //   {
      //     timestamp: '2024-08-05T00:00:00',
      //     value: 448603865.9173006,
      //   },
      //   {
      //     timestamp: '2024-08-06T00:00:00',
      //     value: 141190595.89061508,
      //   },
      //   {
      //     timestamp: '2024-08-07T00:00:00',
      //     value: 131843640.94752747,
      //   },
      //   {
      //     timestamp: '2024-08-08T00:00:00',
      //     value: 112168511.29462321,
      //   },
      //   {
      //     timestamp: '2024-08-09T00:00:00',
      //     value: 243421136.35671717,
      //   },
      //   {
      //     timestamp: '2024-08-10T00:00:00',
      //     value: 85880962.04314227,
      //   },
      //   {
      //     timestamp: '2024-08-11T00:00:00',
      //     value: 67087412.57545641,
      //   },
      //   {
      //     timestamp: '2024-08-12T00:00:00',
      //     value: 110403392.90135518,
      //   },
      //   {
      //     timestamp: '2024-08-13T00:00:00',
      //     value: 111597194.71472852,
      //   },
      //   {
      //     timestamp: '2024-08-14T00:00:00',
      //     value: 109042977.66663535,
      //   },
      //   {
      //     timestamp: '2024-08-15T00:00:00',
      //     value: 147462066.42749357,
      //   },
      //   {
      //     timestamp: '2024-08-16T00:00:00',
      //     value: 106523368.6869495,
      //   },
      //   {
      //     timestamp: '2024-08-17T00:00:00',
      //     value: 68817966.26521394,
      //   },
      //   {
      //     timestamp: '2024-08-18T00:00:00',
      //     value: 64113424.643184,
      //   },
      //   {
      //     timestamp: '2024-08-19T00:00:00',
      //     value: 462543411.1357907,
      //   },
      //   {
      //     timestamp: '2024-08-20T00:00:00',
      //     value: 146526007.51095662,
      //   },
      //   {
      //     timestamp: '2024-08-21T00:00:00',
      //     value: 152829532.59491834,
      //   },
      //   {
      //     timestamp: '2024-08-22T00:00:00',
      //     value: 152392187.49178764,
      //   },
      //   {
      //     timestamp: '2024-08-23T00:00:00',
      //     value: 141566806.87954056,
      //   },
      //   {
      //     timestamp: '2024-08-24T00:00:00',
      //     value: 95854789.11333653,
      //   },
      //   {
      //     timestamp: '2024-08-25T00:00:00',
      //     value: 64769408.270161554,
      //   },
      //   {
      //     timestamp: '2024-08-26T00:00:00',
      //     value: 100786657.57866631,
      //   },
      //   {
      //     timestamp: '2024-08-27T00:00:00',
      //     value: 188718666.91477534,
      //   },
      //   {
      //     timestamp: '2024-08-28T00:00:00',
      //     value: 146260558.3516502,
      //   },
      //   {
      //     timestamp: '2024-08-29T00:00:00',
      //     value: 130764596.22211796,
      //   },
      //   {
      //     timestamp: '2024-08-30T00:00:00',
      //     value: 130391634.99007177,
      //   },
      //   {
      //     timestamp: '2024-08-31T00:00:00',
      //     value: 93306761.15910655,
      //   },
      //   {
      //     timestamp: '2024-09-01T00:00:00',
      //     value: 88738031.90558173,
      //   },
      //   {
      //     timestamp: '2024-09-02T00:00:00',
      //     value: 107876335.76132332,
      //   },
      //   {
      //     timestamp: '2024-09-03T00:00:00',
      //     value: 372639451.502278,
      //   },
      //   {
      //     timestamp: '2024-09-04T00:00:00',
      //     value: 163969111.00985754,
      //   },
      //   {
      //     timestamp: '2024-09-05T00:00:00',
      //     value: 104208549.50722413,
      //   },
      //   {
      //     timestamp: '2024-09-06T00:00:00',
      //     value: 138928137.26310015,
      //   },
      //   {
      //     timestamp: '2024-09-07T00:00:00',
      //     value: 68733304.42503524,
      //   },
      //   {
      //     timestamp: '2024-09-08T00:00:00',
      //     value: 608372070.5250729,
      //   },
      //   {
      //     timestamp: '2024-09-09T00:00:00',
      //     value: 149875520.852186,
      //   },
      //   {
      //     timestamp: '2024-09-10T00:00:00',
      //     value: 133972231.60623041,
      //   },
      //   {
      //     timestamp: '2024-09-11T00:00:00',
      //     value: 138124757.8058993,
      //   },
      //   {
      //     timestamp: '2024-09-12T00:00:00',
      //     value: 154649809.77729484,
      //   },
      //   {
      //     timestamp: '2024-09-13T00:00:00',
      //     value: 159534014.7114593,
      //   },
      //   {
      //     timestamp: '2024-09-14T00:00:00',
      //     value: 105881053.94171956,
      //   },
      //   {
      //     timestamp: '2024-09-15T00:00:00',
      //     value: 91179369.37155074,
      //   },
      //   {
      //     timestamp: '2024-09-16T00:00:00',
      //     value: 151558208.43830186,
      //   },
      //   {
      //     timestamp: '2024-09-17T00:00:00',
      //     value: 171280887.5588046,
      //   },
      //   {
      //     timestamp: '2024-09-18T00:00:00',
      //     value: 141501688.10390764,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: 33712793.53369096,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: 577979883.9220008,
      //   },
      // ],
      // netflowData: [
      //   {
      //     timestamp: '2024-06-22T00:00:00',
      //     value: 31714.529539436102,
      //   },
      //   {
      //     timestamp: '2024-06-23T00:00:00',
      //     value: -5122927.292697802,
      //   },
      //   {
      //     timestamp: '2024-06-24T00:00:00',
      //     value: 254343032.25792748,
      //   },
      //   {
      //     timestamp: '2024-06-25T00:00:00',
      //     value: -13073076.400751919,
      //   },
      //   {
      //     timestamp: '2024-06-26T00:00:00',
      //     value: -5321511.624690458,
      //   },
      //   {
      //     timestamp: '2024-06-27T00:00:00',
      //     value: -1165153.8863028288,
      //   },
      //   {
      //     timestamp: '2024-06-28T00:00:00',
      //     value: -3320391.7729777843,
      //   },
      //   {
      //     timestamp: '2024-06-29T00:00:00',
      //     value: -765022.8080444634,
      //   },
      //   {
      //     timestamp: '2024-06-30T00:00:00',
      //     value: -3374255.6572838575,
      //   },
      //   {
      //     timestamp: '2024-07-01T00:00:00',
      //     value: 21641052.416537628,
      //   },
      //   {
      //     timestamp: '2024-07-02T00:00:00',
      //     value: -4761487.150378019,
      //   },
      //   {
      //     timestamp: '2024-07-03T00:00:00',
      //     value: 2720653.757572472,
      //   },
      //   {
      //     timestamp: '2024-07-04T00:00:00',
      //     value: -6453533.014218301,
      //   },
      //   {
      //     timestamp: '2024-07-05T00:00:00',
      //     value: 7533399.5172608495,
      //   },
      //   {
      //     timestamp: '2024-07-06T00:00:00',
      //     value: 51554958.87311776,
      //   },
      //   {
      //     timestamp: '2024-07-07T00:00:00',
      //     value: -4892284.080370009,
      //   },
      //   {
      //     timestamp: '2024-07-08T00:00:00',
      //     value: -5427755.001691401,
      //   },
      //   {
      //     timestamp: '2024-07-09T00:00:00',
      //     value: 2044061.4711785167,
      //   },
      //   {
      //     timestamp: '2024-07-10T00:00:00',
      //     value: 5674930.757835865,
      //   },
      //   {
      //     timestamp: '2024-07-11T00:00:00',
      //     value: 499439.59009929,
      //   },
      //   {
      //     timestamp: '2024-07-12T00:00:00',
      //     value: -520951.72162903845,
      //   },
      //   {
      //     timestamp: '2024-07-13T00:00:00',
      //     value: 4998942.24642238,
      //   },
      //   {
      //     timestamp: '2024-07-14T00:00:00',
      //     value: -2375431.612433009,
      //   },
      //   {
      //     timestamp: '2024-07-15T00:00:00',
      //     value: 18542141.137898713,
      //   },
      //   {
      //     timestamp: '2024-07-16T00:00:00',
      //     value: -9971230.494291455,
      //   },
      //   {
      //     timestamp: '2024-07-17T00:00:00',
      //     value: -2783582.209516868,
      //   },
      //   {
      //     timestamp: '2024-07-18T00:00:00',
      //     value: -18093483.54556948,
      //   },
      //   {
      //     timestamp: '2024-07-19T00:00:00',
      //     value: 18613852.206454158,
      //   },
      //   {
      //     timestamp: '2024-07-20T00:00:00',
      //     value: -9596.860304065049,
      //   },
      //   {
      //     timestamp: '2024-07-21T00:00:00',
      //     value: -5801885.288214162,
      //   },
      //   {
      //     timestamp: '2024-07-22T00:00:00',
      //     value: -2484477.6280995607,
      //   },
      //   {
      //     timestamp: '2024-07-23T00:00:00',
      //     value: -6492632.040497974,
      //   },
      //   {
      //     timestamp: '2024-07-24T00:00:00',
      //     value: 3477023.055567518,
      //   },
      //   {
      //     timestamp: '2024-07-25T00:00:00',
      //     value: -9776160.759600371,
      //   },
      //   {
      //     timestamp: '2024-07-26T00:00:00',
      //     value: 7799011.092177808,
      //   },
      //   {
      //     timestamp: '2024-07-27T00:00:00',
      //     value: -3140301.7994143814,
      //   },
      //   {
      //     timestamp: '2024-07-28T00:00:00',
      //     value: -1433342.1794424802,
      //   },
      //   {
      //     timestamp: '2024-07-29T00:00:00',
      //     value: -12934424.370607197,
      //   },
      //   {
      //     timestamp: '2024-07-30T00:00:00',
      //     value: -1486927.3098176718,
      //   },
      //   {
      //     timestamp: '2024-07-31T00:00:00',
      //     value: -2318271.5813833773,
      //   },
      //   {
      //     timestamp: '2024-08-01T00:00:00',
      //     value: 8520374.837898105,
      //   },
      //   {
      //     timestamp: '2024-08-02T00:00:00',
      //     value: -275268.52106292546,
      //   },
      //   {
      //     timestamp: '2024-08-03T00:00:00',
      //     value: -8237183.661119878,
      //   },
      //   {
      //     timestamp: '2024-08-04T00:00:00',
      //     value: 3215574.274658814,
      //   },
      //   {
      //     timestamp: '2024-08-05T00:00:00',
      //     value: -1611002.7955349684,
      //   },
      //   {
      //     timestamp: '2024-08-06T00:00:00',
      //     value: 4837454.388280779,
      //   },
      //   {
      //     timestamp: '2024-08-07T00:00:00',
      //     value: 7766746.275440872,
      //   },
      //   {
      //     timestamp: '2024-08-08T00:00:00',
      //     value: 3399982.8175307363,
      //   },
      //   {
      //     timestamp: '2024-08-09T00:00:00',
      //     value: 359500.2517237663,
      //   },
      //   {
      //     timestamp: '2024-08-10T00:00:00',
      //     value: 5288594.5817771405,
      //   },
      //   {
      //     timestamp: '2024-08-11T00:00:00',
      //     value: -4467461.927790403,
      //   },
      //   {
      //     timestamp: '2024-08-12T00:00:00',
      //     value: 3945888.458889097,
      //   },
      //   {
      //     timestamp: '2024-08-13T00:00:00',
      //     value: 1806539.5281775743,
      //   },
      //   {
      //     timestamp: '2024-08-14T00:00:00',
      //     value: -2106138.421716869,
      //   },
      //   {
      //     timestamp: '2024-08-15T00:00:00',
      //     value: 4853901.909905851,
      //   },
      //   {
      //     timestamp: '2024-08-16T00:00:00',
      //     value: -2765045.970509574,
      //   },
      //   {
      //     timestamp: '2024-08-17T00:00:00',
      //     value: 2776107.6357533857,
      //   },
      //   {
      //     timestamp: '2024-08-18T00:00:00',
      //     value: -9970592.189167239,
      //   },
      //   {
      //     timestamp: '2024-08-19T00:00:00',
      //     value: 10899101.342475414,
      //   },
      //   {
      //     timestamp: '2024-08-20T00:00:00',
      //     value: -17236408.9738878,
      //   },
      //   {
      //     timestamp: '2024-08-21T00:00:00',
      //     value: 384189.9245764613,
      //   },
      //   {
      //     timestamp: '2024-08-22T00:00:00',
      //     value: 24442124.43843077,
      //   },
      //   {
      //     timestamp: '2024-08-23T00:00:00',
      //     value: -4043204.0671955943,
      //   },
      //   {
      //     timestamp: '2024-08-24T00:00:00',
      //     value: -10796159.840871736,
      //   },
      //   {
      //     timestamp: '2024-08-25T00:00:00',
      //     value: -9919341.329762876,
      //   },
      //   {
      //     timestamp: '2024-08-26T00:00:00',
      //     value: -21203407.725416884,
      //   },
      //   {
      //     timestamp: '2024-08-27T00:00:00',
      //     value: 7288503.9293216765,
      //   },
      //   {
      //     timestamp: '2024-08-28T00:00:00',
      //     value: -4221971.521478236,
      //   },
      //   {
      //     timestamp: '2024-08-29T00:00:00',
      //     value: 4445146.176137656,
      //   },
      //   {
      //     timestamp: '2024-08-30T00:00:00',
      //     value: -5189528.591538191,
      //   },
      //   {
      //     timestamp: '2024-08-31T00:00:00',
      //     value: 22963438.298172995,
      //   },
      //   {
      //     timestamp: '2024-09-01T00:00:00',
      //     value: 3098893.310328841,
      //   },
      //   {
      //     timestamp: '2024-09-02T00:00:00',
      //     value: -782199.504401207,
      //   },
      //   {
      //     timestamp: '2024-09-03T00:00:00',
      //     value: -277566535.2799846,
      //   },
      //   {
      //     timestamp: '2024-09-04T00:00:00',
      //     value: -6625193.42373386,
      //   },
      //   {
      //     timestamp: '2024-09-05T00:00:00',
      //     value: -17711634.167593494,
      //   },
      //   {
      //     timestamp: '2024-09-06T00:00:00',
      //     value: 7223514.1854608655,
      //   },
      //   {
      //     timestamp: '2024-09-07T00:00:00',
      //     value: -2208246.5734719634,
      //   },
      //   {
      //     timestamp: '2024-09-08T00:00:00',
      //     value: 1862858.7585092783,
      //   },
      //   {
      //     timestamp: '2024-09-09T00:00:00',
      //     value: 10912685.48195669,
      //   },
      //   {
      //     timestamp: '2024-09-10T00:00:00',
      //     value: -4370047.768750131,
      //   },
      //   {
      //     timestamp: '2024-09-11T00:00:00',
      //     value: -2768573.8011084497,
      //   },
      //   {
      //     timestamp: '2024-09-12T00:00:00',
      //     value: 2458458.6493409276,
      //   },
      //   {
      //     timestamp: '2024-09-13T00:00:00',
      //     value: 16401608.230515659,
      //   },
      //   {
      //     timestamp: '2024-09-14T00:00:00',
      //     value: -4899218.62880294,
      //   },
      //   {
      //     timestamp: '2024-09-15T00:00:00',
      //     value: 2880581.716947913,
      //   },
      //   {
      //     timestamp: '2024-09-16T00:00:00',
      //     value: -18780021.876085848,
      //   },
      //   {
      //     timestamp: '2024-09-17T00:00:00',
      //     value: 10918701.821314305,
      //   },
      //   {
      //     timestamp: '2024-09-18T00:00:00',
      //     value: 3797694.13422215,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: 98438.95442704856,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: -430184195.7111652,
      //   },
      // ],
      // outflowData: [
      //   {
      //     timestamp: '2024-06-22T00:00:00',
      //     value: 67596420.45522349,
      //   },
      //   {
      //     timestamp: '2024-06-23T00:00:00',
      //     value: 58947887.35972385,
      //   },
      //   {
      //     timestamp: '2024-06-24T00:00:00',
      //     value: 141952443.57168382,
      //   },
      //   {
      //     timestamp: '2024-06-25T00:00:00',
      //     value: 161800499.08924752,
      //   },
      //   {
      //     timestamp: '2024-06-26T00:00:00',
      //     value: 121814730.68271829,
      //   },
      //   {
      //     timestamp: '2024-06-27T00:00:00',
      //     value: 111838145.34427533,
      //   },
      //   {
      //     timestamp: '2024-06-28T00:00:00',
      //     value: 114784268.71808693,
      //   },
      //   {
      //     timestamp: '2024-06-29T00:00:00',
      //     value: 58451479.5191724,
      //   },
      //   {
      //     timestamp: '2024-06-30T00:00:00',
      //     value: 58881234.35892054,
      //   },
      //   {
      //     timestamp: '2024-07-01T00:00:00',
      //     value: 119219885.31349199,
      //   },
      //   {
      //     timestamp: '2024-07-02T00:00:00',
      //     value: 129513542.21913569,
      //   },
      //   {
      //     timestamp: '2024-07-03T00:00:00',
      //     value: 148476122.03908473,
      //   },
      //   {
      //     timestamp: '2024-07-04T00:00:00',
      //     value: 139599790.25802818,
      //   },
      //   {
      //     timestamp: '2024-07-05T00:00:00',
      //     value: 161246138.35631078,
      //   },
      //   {
      //     timestamp: '2024-07-06T00:00:00',
      //     value: 95335399.28531174,
      //   },
      //   {
      //     timestamp: '2024-07-07T00:00:00',
      //     value: 55171607.41313593,
      //   },
      //   {
      //     timestamp: '2024-07-08T00:00:00',
      //     value: 114770454.17542312,
      //   },
      //   {
      //     timestamp: '2024-07-09T00:00:00',
      //     value: 95873789.29937714,
      //   },
      //   {
      //     timestamp: '2024-07-10T00:00:00',
      //     value: 87353717.427001,
      //   },
      //   {
      //     timestamp: '2024-07-11T00:00:00',
      //     value: 86474353.77550131,
      //   },
      //   {
      //     timestamp: '2024-07-12T00:00:00',
      //     value: 86875941.31412236,
      //   },
      //   {
      //     timestamp: '2024-07-13T00:00:00',
      //     value: 55147923.8048098,
      //   },
      //   {
      //     timestamp: '2024-07-14T00:00:00',
      //     value: 58044722.41093931,
      //   },
      //   {
      //     timestamp: '2024-07-15T00:00:00',
      //     value: 154861788.98097593,
      //   },
      //   {
      //     timestamp: '2024-07-16T00:00:00',
      //     value: 188849751.32921124,
      //   },
      //   {
      //     timestamp: '2024-07-17T00:00:00',
      //     value: 125567754.05864002,
      //   },
      //   {
      //     timestamp: '2024-07-18T00:00:00',
      //     value: 158793623.39586502,
      //   },
      //   {
      //     timestamp: '2024-07-19T00:00:00',
      //     value: 127687858.40646324,
      //   },
      //   {
      //     timestamp: '2024-07-20T00:00:00',
      //     value: 61624333.608056985,
      //   },
      //   {
      //     timestamp: '2024-07-21T00:00:00',
      //     value: 81600468.98919408,
      //   },
      //   {
      //     timestamp: '2024-07-22T00:00:00',
      //     value: 153814679.32167307,
      //   },
      //   {
      //     timestamp: '2024-07-23T00:00:00',
      //     value: 129086434.67968905,
      //   },
      //   {
      //     timestamp: '2024-07-24T00:00:00',
      //     value: 114709573.6274986,
      //   },
      //   {
      //     timestamp: '2024-07-25T00:00:00',
      //     value: 122899262.12534396,
      //   },
      //   {
      //     timestamp: '2024-07-26T00:00:00',
      //     value: 152196231.17088294,
      //   },
      //   {
      //     timestamp: '2024-07-27T00:00:00',
      //     value: 103155454.93111907,
      //   },
      //   {
      //     timestamp: '2024-07-28T00:00:00',
      //     value: 63545337.18544406,
      //   },
      //   {
      //     timestamp: '2024-07-29T00:00:00',
      //     value: 166935736.81464243,
      //   },
      //   {
      //     timestamp: '2024-07-30T00:00:00',
      //     value: 140739210.65389183,
      //   },
      //   {
      //     timestamp: '2024-07-31T00:00:00',
      //     value: 150035716.17288724,
      //   },
      //   {
      //     timestamp: '2024-08-01T00:00:00',
      //     value: 139720063.46342635,
      //   },
      //   {
      //     timestamp: '2024-08-02T00:00:00',
      //     value: 122086844.80266371,
      //   },
      //   {
      //     timestamp: '2024-08-03T00:00:00',
      //     value: 268082771.0967784,
      //   },
      //   {
      //     timestamp: '2024-08-04T00:00:00',
      //     value: 91457049.70521708,
      //   },
      //   {
      //     timestamp: '2024-08-05T00:00:00',
      //     value: 450214868.71283555,
      //   },
      //   {
      //     timestamp: '2024-08-06T00:00:00',
      //     value: 136353141.5023343,
      //   },
      //   {
      //     timestamp: '2024-08-07T00:00:00',
      //     value: 124076894.6720866,
      //   },
      //   {
      //     timestamp: '2024-08-08T00:00:00',
      //     value: 108768528.47709247,
      //   },
      //   {
      //     timestamp: '2024-08-09T00:00:00',
      //     value: 243061636.1049934,
      //   },
      //   {
      //     timestamp: '2024-08-10T00:00:00',
      //     value: 80592367.46136513,
      //   },
      //   {
      //     timestamp: '2024-08-11T00:00:00',
      //     value: 71554874.50324681,
      //   },
      //   {
      //     timestamp: '2024-08-12T00:00:00',
      //     value: 106457504.44246608,
      //   },
      //   {
      //     timestamp: '2024-08-13T00:00:00',
      //     value: 109790655.18655095,
      //   },
      //   {
      //     timestamp: '2024-08-14T00:00:00',
      //     value: 111149116.08835222,
      //   },
      //   {
      //     timestamp: '2024-08-15T00:00:00',
      //     value: 142608164.51758772,
      //   },
      //   {
      //     timestamp: '2024-08-16T00:00:00',
      //     value: 109288414.65745908,
      //   },
      //   {
      //     timestamp: '2024-08-17T00:00:00',
      //     value: 66041858.62946055,
      //   },
      //   {
      //     timestamp: '2024-08-18T00:00:00',
      //     value: 74084016.83235124,
      //   },
      //   {
      //     timestamp: '2024-08-19T00:00:00',
      //     value: 451644309.7933153,
      //   },
      //   {
      //     timestamp: '2024-08-20T00:00:00',
      //     value: 163762416.48484442,
      //   },
      //   {
      //     timestamp: '2024-08-21T00:00:00',
      //     value: 152445342.67034188,
      //   },
      //   {
      //     timestamp: '2024-08-22T00:00:00',
      //     value: 127950063.05335687,
      //   },
      //   {
      //     timestamp: '2024-08-23T00:00:00',
      //     value: 145610010.94673616,
      //   },
      //   {
      //     timestamp: '2024-08-24T00:00:00',
      //     value: 106650948.95420827,
      //   },
      //   {
      //     timestamp: '2024-08-25T00:00:00',
      //     value: 74688749.59992443,
      //   },
      //   {
      //     timestamp: '2024-08-26T00:00:00',
      //     value: 121990065.3040832,
      //   },
      //   {
      //     timestamp: '2024-08-27T00:00:00',
      //     value: 181430162.98545367,
      //   },
      //   {
      //     timestamp: '2024-08-28T00:00:00',
      //     value: 150482529.87312844,
      //   },
      //   {
      //     timestamp: '2024-08-29T00:00:00',
      //     value: 126319450.0459803,
      //   },
      //   {
      //     timestamp: '2024-08-30T00:00:00',
      //     value: 135581163.58160996,
      //   },
      //   {
      //     timestamp: '2024-08-31T00:00:00',
      //     value: 70343322.86093356,
      //   },
      //   {
      //     timestamp: '2024-09-01T00:00:00',
      //     value: 85639138.59525289,
      //   },
      //   {
      //     timestamp: '2024-09-02T00:00:00',
      //     value: 108658535.26572452,
      //   },
      //   {
      //     timestamp: '2024-09-03T00:00:00',
      //     value: 650205986.7822626,
      //   },
      //   {
      //     timestamp: '2024-09-04T00:00:00',
      //     value: 170594304.4335914,
      //   },
      //   {
      //     timestamp: '2024-09-05T00:00:00',
      //     value: 121920183.67481762,
      //   },
      //   {
      //     timestamp: '2024-09-06T00:00:00',
      //     value: 131704623.07763928,
      //   },
      //   {
      //     timestamp: '2024-09-07T00:00:00',
      //     value: 70941550.9985072,
      //   },
      //   {
      //     timestamp: '2024-09-08T00:00:00',
      //     value: 606509211.7665637,
      //   },
      //   {
      //     timestamp: '2024-09-09T00:00:00',
      //     value: 138962835.3702293,
      //   },
      //   {
      //     timestamp: '2024-09-10T00:00:00',
      //     value: 138342279.37498054,
      //   },
      //   {
      //     timestamp: '2024-09-11T00:00:00',
      //     value: 140893331.60700774,
      //   },
      //   {
      //     timestamp: '2024-09-12T00:00:00',
      //     value: 152191351.12795392,
      //   },
      //   {
      //     timestamp: '2024-09-13T00:00:00',
      //     value: 143132406.48094365,
      //   },
      //   {
      //     timestamp: '2024-09-14T00:00:00',
      //     value: 110780272.5705225,
      //   },
      //   {
      //     timestamp: '2024-09-15T00:00:00',
      //     value: 88298787.65460283,
      //   },
      //   {
      //     timestamp: '2024-09-16T00:00:00',
      //     value: 170338230.3143877,
      //   },
      //   {
      //     timestamp: '2024-09-17T00:00:00',
      //     value: 160362185.7374903,
      //   },
      //   {
      //     timestamp: '2024-09-18T00:00:00',
      //     value: 137703993.9696855,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: 33614354.57926391,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: 1008164079.633166,
      //   },
      // ],
      // totalFlowData: [
      //   {
      //     timestamp: '2024-06-22T00:00:00',
      //     value: 135224555.4399864,
      //   },
      //   {
      //     timestamp: '2024-06-23T00:00:00',
      //     value: 112772847.4267499,
      //   },
      //   {
      //     timestamp: '2024-06-24T00:00:00',
      //     value: 538247919.4012952,
      //   },
      //   {
      //     timestamp: '2024-06-25T00:00:00',
      //     value: 310527921.7777431,
      //   },
      //   {
      //     timestamp: '2024-06-26T00:00:00',
      //     value: 238307949.74074614,
      //   },
      //   {
      //     timestamp: '2024-06-27T00:00:00',
      //     value: 222511136.80224782,
      //   },
      //   {
      //     timestamp: '2024-06-28T00:00:00',
      //     value: 226248145.6631961,
      //   },
      //   {
      //     timestamp: '2024-06-29T00:00:00',
      //     value: 116137936.23030034,
      //   },
      //   {
      //     timestamp: '2024-06-30T00:00:00',
      //     value: 114388213.06055722,
      //   },
      //   {
      //     timestamp: '2024-07-01T00:00:00',
      //     value: 260080823.04352158,
      //   },
      //   {
      //     timestamp: '2024-07-02T00:00:00',
      //     value: 254265597.28789335,
      //   },
      //   {
      //     timestamp: '2024-07-03T00:00:00',
      //     value: 299672897.83574194,
      //   },
      //   {
      //     timestamp: '2024-07-04T00:00:00',
      //     value: 272746047.5018381,
      //   },
      //   {
      //     timestamp: '2024-07-05T00:00:00',
      //     value: 330025676.2298824,
      //   },
      //   {
      //     timestamp: '2024-07-06T00:00:00',
      //     value: 242225757.44374126,
      //   },
      //   {
      //     timestamp: '2024-07-07T00:00:00',
      //     value: 105450930.74590185,
      //   },
      //   {
      //     timestamp: '2024-07-08T00:00:00',
      //     value: 224113153.34915483,
      //   },
      //   {
      //     timestamp: '2024-07-09T00:00:00',
      //     value: 193791640.06993282,
      //   },
      //   {
      //     timestamp: '2024-07-10T00:00:00',
      //     value: 180382365.61183786,
      //   },
      //   {
      //     timestamp: '2024-07-11T00:00:00',
      //     value: 173448147.1411019,
      //   },
      //   {
      //     timestamp: '2024-07-12T00:00:00',
      //     value: 173230930.90661567,
      //   },
      //   {
      //     timestamp: '2024-07-13T00:00:00',
      //     value: 115294789.85604198,
      //   },
      //   {
      //     timestamp: '2024-07-14T00:00:00',
      //     value: 113714013.20944563,
      //   },
      //   {
      //     timestamp: '2024-07-15T00:00:00',
      //     value: 328265719.09985054,
      //   },
      //   {
      //     timestamp: '2024-07-16T00:00:00',
      //     value: 367728272.16413105,
      //   },
      //   {
      //     timestamp: '2024-07-17T00:00:00',
      //     value: 248351925.90776318,
      //   },
      //   {
      //     timestamp: '2024-07-18T00:00:00',
      //     value: 299493763.24616057,
      //   },
      //   {
      //     timestamp: '2024-07-19T00:00:00',
      //     value: 273989569.0193806,
      //   },
      //   {
      //     timestamp: '2024-07-20T00:00:00',
      //     value: 123239070.3558099,
      //   },
      //   {
      //     timestamp: '2024-07-21T00:00:00',
      //     value: 157399052.69017398,
      //   },
      //   {
      //     timestamp: '2024-07-22T00:00:00',
      //     value: 305144881.01524657,
      //   },
      //   {
      //     timestamp: '2024-07-23T00:00:00',
      //     value: 251680237.31888014,
      //   },
      //   {
      //     timestamp: '2024-07-24T00:00:00',
      //     value: 232896170.3105647,
      //   },
      //   {
      //     timestamp: '2024-07-25T00:00:00',
      //     value: 236022363.49108756,
      //   },
      //   {
      //     timestamp: '2024-07-26T00:00:00',
      //     value: 312191473.4339437,
      //   },
      //   {
      //     timestamp: '2024-07-27T00:00:00',
      //     value: 203170608.06282377,
      //   },
      //   {
      //     timestamp: '2024-07-28T00:00:00',
      //     value: 125657332.19144563,
      //   },
      //   {
      //     timestamp: '2024-07-29T00:00:00',
      //     value: 320937049.25867766,
      //   },
      //   {
      //     timestamp: '2024-07-30T00:00:00',
      //     value: 279991493.997966,
      //   },
      //   {
      //     timestamp: '2024-07-31T00:00:00',
      //     value: 297753160.76439106,
      //   },
      //   {
      //     timestamp: '2024-08-01T00:00:00',
      //     value: 287960501.76475084,
      //   },
      //   {
      //     timestamp: '2024-08-02T00:00:00',
      //     value: 243898421.08426452,
      //   },
      //   {
      //     timestamp: '2024-08-03T00:00:00',
      //     value: 527928358.5324369,
      //   },
      //   {
      //     timestamp: '2024-08-04T00:00:00',
      //     value: 186129673.685093,
      //   },
      //   {
      //     timestamp: '2024-08-05T00:00:00',
      //     value: 898818734.6301361,
      //   },
      //   {
      //     timestamp: '2024-08-06T00:00:00',
      //     value: 277543737.39294934,
      //   },
      //   {
      //     timestamp: '2024-08-07T00:00:00',
      //     value: 255920535.61961406,
      //   },
      //   {
      //     timestamp: '2024-08-08T00:00:00',
      //     value: 220937039.7717157,
      //   },
      //   {
      //     timestamp: '2024-08-09T00:00:00',
      //     value: 486482772.4617106,
      //   },
      //   {
      //     timestamp: '2024-08-10T00:00:00',
      //     value: 166473329.50450742,
      //   },
      //   {
      //     timestamp: '2024-08-11T00:00:00',
      //     value: 138642287.07870322,
      //   },
      //   {
      //     timestamp: '2024-08-12T00:00:00',
      //     value: 216860897.34382126,
      //   },
      //   {
      //     timestamp: '2024-08-13T00:00:00',
      //     value: 221387849.90127945,
      //   },
      //   {
      //     timestamp: '2024-08-14T00:00:00',
      //     value: 220192093.75498757,
      //   },
      //   {
      //     timestamp: '2024-08-15T00:00:00',
      //     value: 290070230.9450813,
      //   },
      //   {
      //     timestamp: '2024-08-16T00:00:00',
      //     value: 215811783.34440857,
      //   },
      //   {
      //     timestamp: '2024-08-17T00:00:00',
      //     value: 134859824.89467448,
      //   },
      //   {
      //     timestamp: '2024-08-18T00:00:00',
      //     value: 138197441.47553524,
      //   },
      //   {
      //     timestamp: '2024-08-19T00:00:00',
      //     value: 914187720.929106,
      //   },
      //   {
      //     timestamp: '2024-08-20T00:00:00',
      //     value: 310288423.99580103,
      //   },
      //   {
      //     timestamp: '2024-08-21T00:00:00',
      //     value: 305274875.2652602,
      //   },
      //   {
      //     timestamp: '2024-08-22T00:00:00',
      //     value: 280342250.5451445,
      //   },
      //   {
      //     timestamp: '2024-08-23T00:00:00',
      //     value: 287176817.8262767,
      //   },
      //   {
      //     timestamp: '2024-08-24T00:00:00',
      //     value: 202505738.06754482,
      //   },
      //   {
      //     timestamp: '2024-08-25T00:00:00',
      //     value: 139458157.87008598,
      //   },
      //   {
      //     timestamp: '2024-08-26T00:00:00',
      //     value: 222776722.8827495,
      //   },
      //   {
      //     timestamp: '2024-08-27T00:00:00',
      //     value: 370148829.900229,
      //   },
      //   {
      //     timestamp: '2024-08-28T00:00:00',
      //     value: 296743088.22477865,
      //   },
      //   {
      //     timestamp: '2024-08-29T00:00:00',
      //     value: 257084046.26809826,
      //   },
      //   {
      //     timestamp: '2024-08-30T00:00:00',
      //     value: 265972798.57168174,
      //   },
      //   {
      //     timestamp: '2024-08-31T00:00:00',
      //     value: 163650084.0200401,
      //   },
      //   {
      //     timestamp: '2024-09-01T00:00:00',
      //     value: 174377170.5008346,
      //   },
      //   {
      //     timestamp: '2024-09-02T00:00:00',
      //     value: 216534871.02704784,
      //   },
      //   {
      //     timestamp: '2024-09-03T00:00:00',
      //     value: 1022845438.2845405,
      //   },
      //   {
      //     timestamp: '2024-09-04T00:00:00',
      //     value: 334563415.4434489,
      //   },
      //   {
      //     timestamp: '2024-09-05T00:00:00',
      //     value: 226128733.18204176,
      //   },
      //   {
      //     timestamp: '2024-09-06T00:00:00',
      //     value: 270632760.3407394,
      //   },
      //   {
      //     timestamp: '2024-09-07T00:00:00',
      //     value: 139674855.42354244,
      //   },
      //   {
      //     timestamp: '2024-09-08T00:00:00',
      //     value: 1214881282.2916365,
      //   },
      //   {
      //     timestamp: '2024-09-09T00:00:00',
      //     value: 288838356.2224153,
      //   },
      //   {
      //     timestamp: '2024-09-10T00:00:00',
      //     value: 272314510.98121095,
      //   },
      //   {
      //     timestamp: '2024-09-11T00:00:00',
      //     value: 279018089.412907,
      //   },
      //   {
      //     timestamp: '2024-09-12T00:00:00',
      //     value: 306841160.90524876,
      //   },
      //   {
      //     timestamp: '2024-09-13T00:00:00',
      //     value: 302666421.19240296,
      //   },
      //   {
      //     timestamp: '2024-09-14T00:00:00',
      //     value: 216661326.51224208,
      //   },
      //   {
      //     timestamp: '2024-09-15T00:00:00',
      //     value: 179478157.02615356,
      //   },
      //   {
      //     timestamp: '2024-09-16T00:00:00',
      //     value: 321896438.7526896,
      //   },
      //   {
      //     timestamp: '2024-09-17T00:00:00',
      //     value: 331643073.2962949,
      //   },
      //   {
      //     timestamp: '2024-09-18T00:00:00',
      //     value: 279205682.07359314,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: 67327148.11295487,
      //   },
      //   {
      //     timestamp: '2024-09-19T00:00:00',
      //     value: 1741160221.7644076,
      //   },
      // ],
      // hourlyNetflowData: [],
      // hourlyInflowData: [],
      // hourlyOutflowData: [],
      // hourlyTotalFlowData: [],
    },
    {
      id: '66ec3e2744327c6bc842c9bc',
      timestamp: '2024-09-19T14:09:00.567000',
      zScore: 6.646888239824925,
      changePercentage: -8.38,
      changeAmount: -431256377.04834545,
      insight:
        '<p>An anomaly was detected on the total flows for HTX at 2024-09-19 14:09:00. The detected anomaly shows a significant total flow of $1,598,606,759.72, with a z-score of 6.64. The change in the balance is recorded at -$431,256,377.05, representing an 8.38% decrease.</p><p><br></p><p>In the past 3 hours, $437M of BTC was transferred in two separate transactions to a Poloniex deposit address, 1NBX1UZE3EFPTnYNkDfVhRADvVc8v6pRYu. The address has not been active since March 2020.</p><p><br></p><p>Transaction Hashes: <a href="https://mempool.space/tx/b47c3ce6f29987888cbd499a26899ea419ad154a654581707da75eb59eb51733" rel="noopener noreferrer" target="_blank">TRX Hash 1</a> <a href="https://mempool.space/tx/b138cb91c8fe18c79a299fc7b960e47f26c91ea8f3493045d273bf8ae61c1887" rel="noopener noreferrer" target="_blank">TRX HASH 2</a></p>',
      metadata: {
        entityType: 'cex',
        exchange: 'huobi',
        method: 'bp',
        symbol: '_total',
        interval: 'interval',
        source: 'arkham',
        type: 'totalflow',
        units: 'USD',
      },
      entity: {
        displayName: 'huobi',
        isRated: true,
      },
      reviewed: true,
      deleted: false,
      severity: 'high',
      updates: [],
      latestHourlyChaindata: null,
    },
    {
      id: '66ec3e2744327c6bc842c9bb',
      timestamp: '2024-09-19T14:09:00.567000',
      zScore: 6.646888239824925,
      changePercentage: -8.38,
      changeAmount: -431256377.04834545,
      insight:
        '<p>An anomaly was detected on the total flows for HTX at 2024-09-19 14:09:00. The detected anomaly shows a significant total flow of $1,598,606,759.72, with a z-score of 6.64. The change in the balance is recorded at -$431,256,377.05, representing an 8.38% decrease.</p><p><br></p><p>In the past 3 hours, $437M of BTC was transferred in two separate transactions to a Poloniex deposit address, 1NBX1UZE3EFPTnYNkDfVhRADvVc8v6pRYu. The address has not been active since March 2020.</p><p><br></p><p>Transaction Hashes: <a href="https://mempool.space/tx/b47c3ce6f29987888cbd499a26899ea419ad154a654581707da75eb59eb51733" rel="noopener noreferrer" target="_blank">TRX Hash 1</a> <a href="https://mempool.space/tx/b138cb91c8fe18c79a299fc7b960e47f26c91ea8f3493045d273bf8ae61c1887" rel="noopener noreferrer" target="_blank">TRX HASH 2</a></p>',
      metadata: {
        entityType: 'cex',
        exchange: 'huobi',
        method: 'bp',
        interval: 'interval',
        symbol: '_total',
        source: 'arkham',
        type: 'totalflow',
        units: 'USD',
      },
      entity: {
        displayName: 'huobi',
        isRated: true,
      },
      reviewed: true,
      deleted: false,
      severity: 'extreme',
      updates: [],
      latestHourlyChaindata: null,
    },
  ],
};

export const EntityMonitoring = () => {
  // const { ratings, isLoadingRatings, ratingsError } = useRatings();
  //
  // if (isLoadingRatings)
  //   return (
  //     <Container>
  //       <Loader />
  //     </Container>
  //   );
  //
  // if (ratingsError) {
  //   throw ratingsError;
  // }
  //
  // if (ratings && !ratings.data.length) {
  //   throw Error('Not found ratings data');
  // }

  return <EntityMonitoringView fetchedData={mocked_data} />;
};
