import styled from '@emotion/styled';
import { Link } from '@mui/material';

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  overflow-x: scroll;

  > div > :first-child {
    height: 36px;
  }
`;

export const StyledColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledEntityWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const StyledRowDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  max-width: 200px;
`;

export const StyledLink = styled(Link)`
  p {
    transition: color 0.3s;
  }

  &:hover p {
    color: #5967e3;
  }
`;
