import { MonitoredEntityItem } from '@/views/MonitoringView/components/MonititoredEntityItem/MonitoredEntityItem';
import { IMonitoredEntity } from '@/api/userEntities/userEntities.types';

interface IListOfEntitiesProps {
  monitoredEntities: IMonitoredEntity[];
}

export const ListOfEntities = ({ monitoredEntities }: IListOfEntitiesProps) => {
  return (
    <>
      {monitoredEntities.map((item) => (
        <MonitoredEntityItem
          key={item.agioId}
          agioId={item.agioId}
          entity={item.displayName}
          severity={item.latestAnomaly?.severity}
          balances={item.balances}
        />
      ))}
    </>
  );
};
