import styled from '@emotion/styled';

export const StyledPageContainer = styled.div`
  display: flex;
`;

export const StyledAlertDetailsContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

//TODO: this is a temporary solution, styles of the view must be improved with border-box setup
export const StyledTemporaryFix = styled.div`
  * {
    box-sizing: content-box;
  }
`;
