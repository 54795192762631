import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { USER_ENTITIES_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IMonitoredEntity } from '@/api/userEntities/userEntities.types';

export const useGetUserEntities = () => {
  const { userEntitiesApi } = useApi();

  const {
    data: fetchedUserEntities,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IMonitoredEntity[], Error>({
    queryKey: [USER_ENTITIES_KEY, 'GET'],
    queryFn: () => {
      return userEntitiesApi
        .getUserEntities()
        .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load user entities data`)));
    },
  });

  return {
    fetchedUserEntities,
    isLoading,
    isSuccess,
    error,
  };
};
