import { AppBar, Link, Toolbar, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import styled from '@emotion/styled';
import InfoIcon from '@/assets/icons/info.svg';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { StyledButton } from '@/components/molecules/Headers/MonitoringHeader.styled';
import { ERoutes } from '@/types/routes';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const MonitoringHeader = () => {
  const { shouldDisplayAlerts } = usePermissionsManager();

  if (!shouldDisplayAlerts) {
    return (
      <StyledHeader position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
                Risk Monitoring
              </Typography>
            </div>
          </div>
        </Toolbar>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
              Risk Monitoring
            </Typography>
          </div>
          <Tooltip arrow title="Risk Monitoring">
            <img src={InfoIcon} alt="Info icon" />
          </Tooltip>
        </div>

        <Tooltip arrow TransitionComponent={Zoom} placement="left" title="Monitoring settings">
          <Link href={ERoutes.MONITORING_SETTINGS} underline="none">
            <StyledButton variant="outlined" color="tertiary" skipTypography>
              <SettingsIcon />
              Settings
            </StyledButton>
          </Link>
        </Tooltip>
      </Toolbar>
    </StyledHeader>
  );
};

export default MonitoringHeader;
