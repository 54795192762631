import styled from '@emotion/styled';
import {
  FEEDBACK_BUTTON_HEIGHT,
  FEEDBACK_BUTTON_PADDING,
  HEADER_HEIGHT,
  SIDE_BAR_WIDTH,
} from '@/layouts/MainLayout/constants';

export const StyledAppContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export const StyledHeader = styled.div`
  width: calc(100% - ${SIDE_BAR_WIDTH});
  position: fixed;
  top: 0;
  left: ${SIDE_BAR_WIDTH};
  z-index: 1;
`;

export const StyledMain = styled.main`
  padding-bottom: calc(2 * ${FEEDBACK_BUTTON_PADDING} + ${FEEDBACK_BUTTON_HEIGHT});
  width: calc(100% - ${SIDE_BAR_WIDTH});
  position: relative;
  top: ${HEADER_HEIGHT};
  left: ${SIDE_BAR_WIDTH};
`;
