import { Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import { formatDateToAlert } from '@/helpers/helpers';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import {
  StyledDataGrid,
  StyledWrapper,
  StyledLink,
} from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable.styled';
import { EntityMonitoredData } from '@/views/EntityMonitoringView/EntityMonitoring';
import { TSeverityLevelValue } from '@/types/severityLevel';
import { TAnomaly } from '@/types/anomaly';

interface MyGridCellParams extends GridCellParams {
  row: TAnomaly;
  value: number;
}

export const AnomaliesTable = ({ anomalies }: Pick<EntityMonitoredData, 'anomalies'>) => {
  const columns: any = [
    {
      field: 'severity',
      headerName: 'Severity',
      width: 150,
      sortable: false,
      renderCell: (params: MyGridCellParams) => {
        const severity = params.value?.toString() as TSeverityLevelValue;

        return <AlertSeverityBarsIcon severity={severity} />;
      },
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <p>{formatDateToAlert(params.row.timestamp)}</p>;
      },
    },
    {
      field: 'type',
      headerName: 'Variable',
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <p>{mapMetadataType(params.row.metadata.type)}</p>;
      },
    },

    {
      headerName: 'Anomaly details',
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StyledLink underline="none">
            <Typography color="white.100" fontSize="14px" display="flex" alignItems="center" gap="3px">
              Go to details <ArrowForwardIcon fontSize="small" />
            </Typography>
          </StyledLink>
        );
      },
    },
  ];

  return (
    <StyledWrapper>
      <Typography color="white.100" fontSize="24px">
        Anomalies
      </Typography>
      <StyledDataGrid style={{ color: '#fff' }} rows={anomalies} columns={columns} disableColumnMenu hideFooter />
    </StyledWrapper>
  );
};
