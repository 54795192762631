import { TSeverityLevelValue } from '@/types/severityLevel';

export interface IAlertId {
  alertId: string;
}

export interface IAlertUpdateId {
  updateId: string;
}

export interface IAlertEntity {
  displayName: string | null;
  isRated: boolean | null;
}

export interface IExtendedAlertDto {
  id: string;
  timestamp: string;
  zScore: number;
  changePercentage: number | null;
  changeAmount: number | null;
  insight: string | null;
  metadata: IAlertMetadata | null;
  reviewed: boolean | null;
  deleted: boolean | null;
  severity: TSeverity;
  updates: IExtendedAlertUpdateDto[];
  chainData: IChainData[] | null;
  inflowData: IFlowData[];
  netflowData: IFlowData[];
  outflowData: IFlowData[];
  totalFlowData: IFlowData[];
  hourlyNetflowData: IFlowData[];
  hourlyInflowData: IFlowData[];
  hourlyOutflowData: IFlowData[];
  hourlyTotalFlowData: IFlowData[];
  latestHourlyChaindata: IChainDataHourly[] | null;
  entity: IAlertEntity | null;
}

export interface IAlertDto {
  id: string;
  timestamp: string;
  metadata: IAlertMetadata | null;
  zScore: number;
  insight: string | null;
  reviewed: boolean | null;
  updates: IAlertUpdateDto[];
  changePercentage: number | null;
  changeAmount: number | null;
  deleted: boolean | null;
  latestHourlyChaindata: IChainDataHourly[] | null;
  entity: IAlertEntity | null;
  severity: TSeverity;
  chainData: IChainData[] | null;
  inflowData: IFlowData[];
  netflowData: IFlowData[];
  outflowData: IFlowData[];
  totalFlowData: IFlowData[];
}

export interface IAlertUpdateDto {
  id: string;
  collection: string;
}

export interface IExtendedAlertUpdateDto {
  id: string | null;
  title: string;
  timestamp: string;
  details: string;
  deleted: boolean | null;
  showChart: boolean;
  chartType: TChartType;
  chainData: IChainData[];
  inflowData: IFlowData[];
  netflowData: IFlowData[];
  outflowData: IFlowData[];
  totalFlowData: IFlowData[];
}

export type TChartType = 'totalbalance' | 'all' | null;

export type TSeverity = TSeverityLevelValue | null;

export interface IChainData {
  deleted: boolean | null;
  intervalStart: string | null;
  metadata: IChainMetadata;
  timestamp: string;
  value: number;
  id: string | null;
}

export interface IChainMetadata {
  exchange: string | null;
  isAggregate: boolean;
  source: string | null;
  symbol: string;
  type: string;
  units: string | null;
}

export interface IFlowData {
  timestamp: string;
  value: number;
}

export interface IAlertMetadata {
  symbol: string | null;
  type: string | null;
  source: string | null;
  exchange: string | null;
  entityType: string | null;
  method: string | null;
  units: string | null;
  interval: string | null;
}

export interface IChainDataHourly {
  id: string | null;
  metadata: IChainMetadata;
  timestamp: string;
  intervalStart: string | null;
  value: number;
  deleted: boolean | null;
}

export enum AlertMetadataType {
  BALANCE = 'balance',
  INFLOW = 'inflow',
  OUTFLOW = 'outflow',
  TOTAL_FLOW = 'totalflow',
  NETFLOW = 'netflow',
}
