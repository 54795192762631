import { Link, Typography } from '@mui/material';
import { StyledWrapper } from '@/views/MonitoringView/components/EmptyMonitoringView/EmptyMonitoringView.styled';
import { ERoutes } from '@/types/routes';

export const EmptyMonitoringView = () => {
  return (
    <StyledWrapper>
      <Typography variant="h2" color="white.100" fontWeight="bold">
        Ooops, there is not data to see!
      </Typography>

      <Typography color="white.100" fontWeight="normal" fontSize="16px" align="center" p="32px">
        It looks like no entities are currently being monitored. <br />
        To start tracking data, please go to the{' '}
        <Link href={ERoutes.MONITORING_SETTINGS} underline="none" color="white.100">
          <b>Settings</b>
        </Link>{' '}
        page and configure the entities you would like to monitor.
      </Typography>
    </StyledWrapper>
  );
};
