import { useState } from 'react';
import styled from '@emotion/styled';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import CustomDatePicker from '@/components/atoms/CustomDatePicker/CustomDatePicker';
import Select from '@/components/molecules/Select/Select';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import InfoIcon from '@/assets/icons/info.svg';
import { useMixpanelTracking } from '@/hooks/useMixpanelTracking';
import MultiSelect from '../MultiSelect/MultiSelect';
import { getKeyByLabel } from '@/helpers/exchangeNames';
import { useAlertsFilters } from '@/providers/AlertsFiltersContextProvider/AlertsFiltersContext.hooks';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { IOption } from '@/types/select';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const WrapperSelect = styled.div`
  width: 350px;
  height: 56px;
  margin-left: 10px;
`;

const timeRangeOptions: IOption[] = [
  { value: 'lastDay', label: 'Last day' },
  { value: 'last15Days', label: 'Last 15 days' },
  { value: 'last30Days', label: 'Last 30 days' },
  { value: 'lastQuarter', label: 'Last quarter' },
  { value: 'custom', label: 'Custom date' },
];
export const AlertsHeaderPermitted = () => {
  const [showCustomDatePickers, setShowCustomDatePickers] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState<string[]>([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState('');
  const { setFilterAlertByExchange, setFilterAlertByTimeRange } = useAlertsFilters();
  const { exchanges } = useEntitiesList();
  const { trackFilterUsage } = useMixpanelTracking();

  const handleOnChangeExchange = (value: string[]) => {
    value = value.map((e) => getKeyByLabel(e));
    setSelectedExchange(value);
    setFilterAlertByExchange(value);
    trackFilterUsage('Exchange Filter', value);
  };

  const handleOnChangeTimeRange = (value: string) => {
    setFilterAlertByTimeRange(value);
    setSelectedTimeRange(value);
    setShowCustomDatePickers(value === 'custom');
    trackFilterUsage('Time Range Filter', value);
  };

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
              Anomaly Alerts
            </Typography>
          </div>
          <Tooltip
            arrow
            title="Anomaly Alerts is an advanced monitoring tool designed to provide near real-time insights into the short-term risk associated with crypto counterparties. It monitors significant variable deviations, indicating short-term credit deterioration in counterparties. These alerts are generated and delivered directly to users, allowing for prompt and informed decision-making regarding exposure management. When used alongside our Statistical Ratings, Anomaly Alerts offers a comprehensive view of the underlying risk, empowering clients to proactively manage and mitigate potential threats in the dynamic crypto market."
          >
            <img src={InfoIcon} alt="Info icon" />
          </Tooltip>
        </div>
        <Box display="flex" alignItems="center">
          {exchanges.length > 0 && (
            <WrapperSelect>
              <MultiSelect
                options={exchanges.map((e) => e.label)}
                onChange={handleOnChangeExchange}
                label={'Exchange'}
                value={selectedExchange}
              />
            </WrapperSelect>
          )}
          <WrapperSelect>
            <Select
              options={timeRangeOptions}
              onChange={handleOnChangeTimeRange}
              value={selectedTimeRange}
              placeholder="Select time range"
            />
            {showCustomDatePickers && selectedTimeRange === 'custom' && <CustomDatePicker />}
          </WrapperSelect>
        </Box>
      </Toolbar>
    </StyledHeader>
  );
};
