import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IMonitoredEntity, IUserEntitiesApi } from '@/api/userEntities/userEntities.types';

export class UserEntitiesApi implements IUserEntitiesApi {
  private getBaseUrl(): string {
    return ApiEndpoints.USER_ENTITIES_BASE_URL;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getUserEntities() {
    return this.httpClient.get<IMonitoredEntity[]>(this.getBaseUrl());
  }
}
