import React from 'react';
import { FormProvider } from 'react-hook-form';
import Button from '@/components/atoms/Button/Button';
import { EntitiesMonitoring } from '@/views/MonitoringSettingsView/components/EntitiesMonitoring/EntitiesMonitoring';
import { useMonitoringForm } from '@/views/MonitoringSettingsView/utils/useMonitoringForm';
import { StyledContainer, StyledButtonContainer } from '@/views/MonitoringSettingsView/MonitoringSettingsView.styled';

export const MonitoringSettingsView = () => {
  const { monitoringMethods, handleMonitoringFormSubmit, isSettingsLoading } = useMonitoringForm();

  return (
    <FormProvider {...monitoringMethods}>
      <form onSubmit={handleMonitoringFormSubmit}>
        <StyledContainer>
          <EntitiesMonitoring formMethods={monitoringMethods} />
          <StyledButtonContainer>
            <Button
              type="submit"
              variant="contained"
              fitContentWidth
              sx={{ minWidth: '142px' }}
              disabled={isSettingsLoading}
            >
              Save
            </Button>
          </StyledButtonContainer>
        </StyledContainer>
      </form>
    </FormProvider>
  );
};
