import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { formatDateToAlert } from '@/helpers/helpers';
import mapChainData from '@/helpers/mapChainData';
import { useGetAlertById } from '@/api/alerts/alerts.hooks';
import AlertDetailsPanel from '@/components/molecules/AlertDetailsPanel/AlertDetailsPanel';
import InsightPanel from '@/components/molecules/InsightPanel/InsightPanel';
import UpdatesPanel from '@/components/molecules/UpdatesPanel/UpatesPanel';
import WrapperCharts from '@/components/molecules/Charts/WrapperCharts';
import Loader from '@/components/atoms/Loader/Loader';
import { mapHourlyData } from '@/helpers/mapHourlyData';
import { StyledTemporaryFix } from '@/views/AlertsView/AlertsView.styled';

const AlertDetails = () => {
  const { id } = useParams<{ id: string }>();
  const alertId = id || '';
  const { fetchedAlert, isSuccess, isLoading, error } = useGetAlertById({ alertId });

  if (isLoading) {
    return (
      <div style={{ paddingTop: '20px' }}>
        <Loader />
      </div>
    );
  }

  if (error) {
    throw error;
  }

  if (!fetchedAlert) {
    return <Typography color="white.100">`{"Requested alert doesn't exist."}`</Typography>;
  }

  const formattedData = fetchedAlert.chainData ? mapChainData(fetchedAlert.chainData) : [];

  const formattedNetFlowData = mapChainData(fetchedAlert.netflowData);
  const formattedTotalFlowData = mapChainData(fetchedAlert.totalFlowData);

  const formattedHourlyNetflowData = mapHourlyData(fetchedAlert.hourlyNetflowData);
  const formattedHourlyTotalflowData = mapHourlyData(fetchedAlert.hourlyTotalFlowData);

  const anomalyChainData = formattedData.sort((a, b) => a.x - b.x)[formattedData.length - 1];

  return (
    <StyledTemporaryFix>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '20px', overflow: 'auto' }}>
        <div style={{ display: 'flex', gap: '16px' }}>
          <AlertDetailsPanel
            companyName={fetchedAlert.metadata?.exchange || ''}
            z_score={fetchedAlert.zScore}
            severity={fetchedAlert.severity}
            timestamp={formatDateToAlert(fetchedAlert.timestamp)}
            entityType={fetchedAlert.metadata?.entityType || ''}
            variable={mapMetadataType(fetchedAlert.metadata?.type)}
            isRated={!!fetchedAlert.entity?.isRated}
          />
          <WrapperCharts
            key={alertId}
            isDataLoaded={isSuccess}
            chartData={formattedData}
            alertId={alertId}
            anomalyChainData={anomalyChainData}
            netflowData={formattedNetFlowData}
            totalflowData={formattedTotalFlowData}
            hourlyNetflowData={formattedHourlyNetflowData}
            hourlyTotalflowData={formattedHourlyTotalflowData}
            alertType={fetchedAlert.metadata?.type}
          />
        </div>
        <div style={{ marginTop: '16px' }}>
          {fetchedAlert.insight && <InsightPanel text={fetchedAlert.insight} />}
          {fetchedAlert.updates.length > 0 && (
            <div style={{ marginTop: '16px' }}>
              <UpdatesPanel
                updates={fetchedAlert.updates}
                alertTimestamp={fetchedAlert.timestamp}
                alertChangeAmount={fetchedAlert.changeAmount}
                alertId={alertId}
              />
            </div>
          )}
        </div>
      </div>
    </StyledTemporaryFix>
  );
};

export default AlertDetails;
