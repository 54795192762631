import { TSeverityLevelValue } from '@/types/severityLevel';

export const adjustSeverityLevel = (zScore: number): TSeverityLevelValue => {
  switch (true) {
    case zScore < -8.5:
      return 'extreme';
    case zScore < -4.2 && zScore >= -8.5:
      return 'high';
    case zScore < -2.5 && zScore >= -4.2:
      return 'medium';
    case zScore <= -1.5 && zScore >= -2.5:
      return 'low';
    default:
      return 'none';
  }
};

export const adjustSeverityColor = (severity: TSeverityLevelValue): string => {
  switch (severity) {
    case 'extreme':
      return '#C00000';
    case 'high':
      return '#F60102';
    case 'medium':
      return '#FAC002';
    case 'low':
      return '#FEFF01';
    default:
      return '#4EA72E';
  }
};
