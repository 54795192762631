import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from '@mui/material';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 24px;
  border: 1px solid #2e2e31;
  width: 100%;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  margin: 0 auto;
  border-radius: 4px;
  background: #1b1b1e;
  width: 70%;

  //Button white text
  .MuiButtonBase-root {
    color: #fff;

    //Button close icon color
    svg {
      fill: rgba(255, 255, 255, 0.54);
    }
  }

  //Input white text
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
  }

  //Main label color behavior
  label {
    color: rgba(255, 255, 255, 0.54);
    transition: color 0.3s;

    &.Mui-focused {
      color: #5d51b8;
    }
  }
`;

export const StyledPaper = styled(Paper)`
  background: #1b1b1e;
  border-radius: 4px;
  color: #fff;

  .MuiAutocomplete-noOptions {
    color: #fff;
  }
`;
