import { StyledContainer, StyledWrapper } from '@/views/EntityMonitoringView/EntityMonitoringView.styled';
import { Typography } from '@mui/material';
import { EntityMonitoredData } from '@/views/EntityMonitoringView/EntityMonitoring';
import { EntityDetails } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';
import { AnomaliesTable } from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable';

interface IEntityMonitoringViewProps {
  fetchedData: EntityMonitoredData;
}

export const EntityMonitoringView = ({ fetchedData }: IEntityMonitoringViewProps) => {
  return (
    <StyledContainer>
      <StyledWrapper>
        <EntityDetails {...fetchedData} />
        {!!fetchedData.anomalies.length && <AnomaliesTable anomalies={fetchedData.anomalies} />}
        <Typography color="white.100">Charts section</Typography>
      </StyledWrapper>
    </StyledContainer>
  );
};
